
import { Options, Vue } from 'vue-class-component';
import { BaseButton, BaseIcon, BaseModal, BaseTextInput, RejectReason } from '@/lib/components';
import { ExternalPatientReference, Patient, RejectReasonValue } from '@/models';
import { EpisodeOfCareResponseData } from '@/models/episode-of-care/episode-of-care.model';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import { getDobFromISOString } from '@/helpers/patient.helper';


@Options({
  props: {
    episodeOfCare: {
      type: Object,
      default: () => ({})
    },
    patient: {
      type: Object,
      default: () => ({})
    },
    error: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseIcon,
    BaseModal,
    BaseButton,
    BaseTextInput,
    RejectReason
  }
})
export default class RejectReasonModal extends Vue {
  episodeOfCare!: EpisodeOfCareResponseData;
  patient!: Patient;
  error!: string;
  loading = false;
  rejectReason: RejectReasonValue = {
    selectedReason: '',
    additionalReason: ''
  };

  rejectReasonError: string = this.error;

  get patientNHSNumber(): string {
    if (this.patient && this.patient.external_patient_references) {
      const nhsEpr = this.patient.external_patient_references.find(
        (epr: ExternalPatientReference) =>
          epr.type ? epr.type.key === UHB_NHS_NUMBER_KEY : false
      );

      if (nhsEpr && nhsEpr.value) {
        return formattedNHSNumber(nhsEpr.value);
      }
    }
    return this.$t('platform.common.none').toString();
  }

  get consultTypeDisplayLabel() {
    return this.episodeOfCare.ophthalmology_details.consult_type.name;
  }

  close() {
    this.$emit('updateErrorMessage', '');
    this.$emit('close');
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  markAsReject() {
    this.$emit('markAsRejected', { ...this.rejectReason });
  }

  onChange(value: RejectReasonValue) {
    this.rejectReason = value;
  }

  updateError(value: number) {
    this.$emit(
      'updateErrorMessage',
      value < 0
        ? this.$t('custom.uhb.consult.error-additional-reason-limit')
        : ''
    );
  }
}
